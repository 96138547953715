<template>
  <div>
    <CModal
        data-sel="mdl-add-clnt"
        title="New Client"
        size="xl"
        :show.sync="showModal"
        @update:show="closeAddModal"
    >
      <CAlert
          data-sel="mdl-add-clnt-error"
          v-if="error"
          color="danger"
          closeButton
          @update:show="removeError"
      >
        {{error}}
      </CAlert>
      <ClientForm
          v-bind:clientIn="client"
          v-on:update-item="updateItem"
          v-on:error="catchError"
      />
    </CModal>
  </div>
</template>

<script>
import gql from "graphql-tag";
import ClientForm from "@/apps/contracts/components/ClientForm";
const clonedeep = require('lodash.clonedeep')

const createClient = gql`
  mutation createClient($client: ClientInput){
    createClient(client: $client) {
      nid
      debitorNumber
      msDebitorNumber
      name
      accountOwner {
        nid
        firstName
        lastName
      }
      vendor{
        nid
        shortName
      }
      isValid
      location
    }
  }
`

export default {
  name: "ClientAdd",
  components: {
    ClientForm
  },
  data(){
    return {
      client: {
        "location": "germany"
      },
      clientTemplate: {
        "location": "germany"
      },
      clientSave: {},
      error: null
    }
  },
  computed: {
    showModal: {
      get: function() {
        return this.$store.state.addModalShow
      },
      set: function (){
      }
    }
  },
  methods: {
    updateItem(data){
      data.accountOwner = data.accountOwner ? {nid: data.accountOwner.nid} : null;
      data.msAccountOwner = data.msAccountOwner ? {nid: data.msAccountOwner.nid} : null;
      data.vendor = data.vendor ? {nid: data.vendor.nid} : null;
      data.salesRegion = data.salesRegion ? {nid: data.salesRegion.nid} : null;
      data.salesOffice = data.salesOffice ? {nid: data.salesOffice.nid} : null;
      data.country = data.country ? data.country.name : null;
      data.debitorCountry = data.debitorCountry ? data.debitorCountry.name : null;
      data.debitorPostalCode = data.debitorPostalCode ? data.debitorPostalCode.postalCode : null;
      data.postalCode = data.postalCode ? data.postalCode.postalCode : null;
      data.portfolioSettings = data.portfolioSettings ? data.portfolioSettings.map(item => {
        delete item.idx
        return item
      }) : null;
      this.clientSave = data
    },
    closeAddModal(status, evt, accept){
      if (accept) {
        this.$apollo.mutate({
          mutation: createClient,
          variables: {
            client: this.clientSave
          },
        })
        .then(res => {
          this.$emit('add-item', res.data.createClient);
          this.client = clonedeep(this.clientTemplate);
          this.clientSave = clonedeep(this.clientTemplate);
          this.$store.commit('set', ['addModalShow', false]);
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
        })
       }else{
        this.client = clonedeep(this.clientTemplate);
        this.clientSave = clonedeep(this.clientTemplate);
        this.$store.commit('set', ['addModalShow', false]);
       }
    },
    catchError(event){
      this.error = event
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    }
  }
}
</script>
